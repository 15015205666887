import { useLayoutEffect, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";


function App() {

  window.location.href = 'https://drx.store';

  return (
    <></>
  );
}

export default App;
